import api from "../config/api.js";

const fetchData = async ({
  page,
  sortBy,
  order,
  limit,
  search,
  patient,
  professional,
  status,
  startDate,
  endDate,
}) => {
  const response = await api.get(
    `/clinic-financial?paginate=true&page=${page}&sortBy=${sortBy}&order=${order}&limit=${limit}&search=${search}&patient=${patient}&professional=${professional}&status=${status}&startDate=${startDate}&endDate=${endDate}`
  );
  return response.data;
};

const fetchFinances = async ({
  patient,
  professional,
  status,
  startDate,
  endDate,
}) => {
  const response = await api.get(
    `/clinic-financial-info?patient=${patient}&professional=${professional}&status=${status}&startDate=${startDate}&endDate=${endDate}`
  );
  return response.data;
};

const apiMethods = {
  fetchData,
  fetchFinances,
};
export default apiMethods;
