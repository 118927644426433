import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import {
  CardList,
  CardListBody,
  CardListBodyItem,
  CardListBodyItemOptions,
} from "../../../../components/CardList";
import { CustomModal } from "../../../../components/Modal";
import PatientsForm from "../../../../components/Forms/Dashboard/_clinics/Patients";
import FilterBar from "../../../../components/FiltersBar";
import { useAuth } from "../../../../context/authContext";
import patientService from "../../../../services/clinicPatientService";
import DataList from "../../../../components/DataList";
import Swal from "sweetalert2";
import { isUserMinor, dateHourFormat } from "../../../../config/utils";

function Patients() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [patient, setPatient] = useState({
    fullName: "",
    email: "",
    phone: "",
    birthDate: "",
    sessionCost: "",
    additionalInfo: "",
  });
  const [filters, setFilters] = useState({
    page: 1,
    sort: { sortBy: "createdAt", order: "desc" },
    limit: 20,
    search: "",
    status: "all",
  });
  const [showModalEditAdd, setshowModalEditAdd] = useState(false);
  const [showModalView, setShowModalView] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isUserAllowed =
    user?.role === "admin" ||
    user?.clinicRole === "owner" ||
    user?.clinicRole === "admin";

  const filterConfig = [
    {
      type: "text",
      label: "Nome do paciente",
      name: "search",
      size: 3,
    },
    {
      type: "select",
      label: "Filtrar por status",
      name: "status",
      options: [
        { label: "Todos", value: "all" },
        { label: "Ativos", value: "active" },
        { label: "Inativos", value: "inactive" },
      ],
      size: 2,
    },
    {
      type: "select",
      label: "# itens",
      name: "limit",
      options: [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "20", value: 20 },
      ],
      size: 1,
    },
  ];

  const refreshItems = async () => {
    setshowModalEditAdd(false);
    setShowModalView(false);
    setPatient({});
    setErrorMessage("");
    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { page, sort, limit, search, status } = filters;
      const params = {
        page,
        sortBy: sort.sortBy,
        order: sort.order,
        limit,
        search,
        status,
      };
      const response = await patientService.fetchData(params);
      setData(response.docs);
      setDataInfo({ ...response });
    } catch (error) {
      console.log(error);
      Swal.fire("Erro!", error.message, "error");
    }
    refreshItems();
  }, [filters]);

  useEffect(() => {
    document.title = "We Care Sync - Pacientes";
    fetchData();
  }, [fetchData]);

  const handleSort = (sortKey) => {
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        sort: {
          sortBy: sortKey,
          order:
            prevFilters.sort.sortBy === sortKey &&
            prevFilters.sort.order === "asc"
              ? "desc"
              : "asc",
        },
      };
      return newFilters;
    });
  };

  const prevPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
    }));
  };

  const nextPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page:
        prevFilters.page < dataInfo.totalPages
          ? prevFilters.page + 1
          : prevFilters.page,
    }));
  };

  const handleInputSearch = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "paymentDay") {
      const sanitizedValue = parseInt(value, 10);
      setPatient((prevItem) => ({
        ...prevItem,
        [name]: isNaN(sanitizedValue)
          ? ""
          : Math.max(1, Math.min(31, sanitizedValue)),
      }));
      return;
    }

    setPatient((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const submitData = async () => {
    setLoading(true);
    try {
      await patientService.submitData(patient);
      await fetchData();
      setLoading(false);
      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: `Paciente ${patient.fullName} salvo com sucesso!`,
        });
      }, 300);
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `Erro ao salvar`,
        });
      }, 300);
      setErrorMessage(error.message);
    }
  };

  const deleteData = async (data) => {
    const result = await Swal.fire({
      title: "Atenção!",
      text: `Deseja realmente ${
        data.status === "active" ? "inativar" : "ativar"
      } o paciente?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      dangerMode: true,
    });

    if (result.isConfirmed) {
      try {
        await patientService.deleteData(data._id);
        await fetchData();
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: `Paciente ${
            data.status === "active" ? "inativado" : "ativado"
          } com sucesso!`,
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `Erro ao ${
            data.status === "active" ? "inativar" : "ativar"
          } o paciente, tente novamente mais tarde.`,
        });
      }
    }
  };

  const renderItem = (item, index) => (
    <CardListBody key={index}>
      <Row>
        <CardListBodyItem
          xs={12}
          lg={isUserAllowed ? 3 : 5}
          className="d-inline-flex align-items-center text-muted small"
          title="Nome:"
          value={item.fullName}
        />
        <CardListBodyItem
          xs={12}
          lg={isUserAllowed ? 3 : 7}
          className="d-inline-flex align-items-center text-muted small"
          title="Informações adicionais:"
          value={item.additionalInfo}
          isHtml={true}
        />
        {isUserAllowed && (
          <CardListBodyItem
            xs={12}
            lg={2}
            className="d-inline-flex align-items-center text-muted small"
            title="E-mail:"
            value={
              isUserMinor(item.birthDate) ? item.guardianEmail : item.email
            }
          />
        )}
        {isUserAllowed && (
          <CardListBodyItem
            xs={12}
            lg={2}
            className="d-inline-flex justify-content-center align-items-center text-muted small"
            title="Data de Cadastro:"
            value={dateHourFormat(item.createdAt)}
          />
        )}
        {isUserAllowed && (
          <CardListBodyItem
            xs={12}
            lg={1}
            className={`d-inline-flex justify-content-center align-items-center fw-bold small ${
              item.status === "active" ? "text-success" : "text-danger"
            }`}
            title="Status:"
            value={item.status === "active" ? "Ativo" : "Inativo"}
          />
        )}
        <CardListBodyItemOptions
          xs={12}
          lg={1}
          className="d-inline-flex justify-content-center align-items-center text-muted small"
          title="Gerenciar:"
          value={item.sessionCost}
        >
          {isUserAllowed && (
            <>
              <Dropdown.Item
                className="text-primary font-semi-bold text-center"
                onClick={() => {
                  setPatient(item);
                  setshowModalEditAdd(true);
                }}
              >
                Editar informações
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger font-semi-bold text-center"
                onClick={() => deleteData(item)}
              >
                {item.status === "active"
                  ? "Inativar paciente"
                  : "Ativar paciente"}
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Item
            className="color-dark-tertiary font-semi-bold text-center"
            onClick={() => {
              setPatient(item);
              setShowModalView(true);
            }}
          >
            Ver Informações
          </Dropdown.Item>
        </CardListBodyItemOptions>
      </Row>
    </CardListBody>
  );

  return (
    <>
      <Sidebar pageName="Pacientes" pageUrl="/pacientes">
        <Container fluid>
          {isUserAllowed && (
            <FilterBar
              config={filterConfig}
              filters={filters}
              onFilterChange={handleInputSearch}
              onAddClick={() => {
                setPatient({});
                setshowModalEditAdd(true);
              }}
              onAddText={"Adicionar Paciente"}
              adminOnly={false}
            />
          )}
          <Row>
            <CardList
              page={parseInt(dataInfo.page)}
              data={data}
              pages={dataInfo.totalPages}
              callbackNext={nextPage}
              callbackPrev={prevPage}
            >
              <DataList
                items={data}
                headers={[
                  {
                    label: "Nome",
                    xs: 12,
                    lg: isUserAllowed ? 3 : 5,
                    sortKey: "fullName",
                    onSort: handleSort,
                  },
                  {
                    label: "Informações Adicionais",
                    xs: 12,
                    lg: isUserAllowed ? 3 : 5,
                  },
                  {
                    label: "E-mail",
                    xs: 12,
                    lg: 2,
                    sortKey: "email",
                    onSort: handleSort,
                    className: `${isUserAllowed ? "d-block" : "d-none"}`,
                  },
                  {
                    label: "Data de Cadastro",
                    xs: 12,
                    lg: 2,
                    sortKey: "createdAt",
                    onSort: handleSort,
                    className: `${
                      isUserAllowed ? "d-flex justify-content-center" : "d-none"
                    }`,
                  },
                  {
                    label: "Status",
                    xs: 12,
                    lg: 1,
                    className: `${
                      isUserAllowed ? "d-flex justify-content-center" : "d-none"
                    }`,
                  },
                  {
                    label: "Gerenciar",
                    xs: 12,
                    lg: 1,
                    className: `${
                      isUserAllowed ? "d-flex justify-content-center" : "d-none"
                    }`,
                  },
                ]}
                renderItem={(item, index) =>
                  renderItem(item, index, setPatient, deleteData)
                }
              />
            </CardList>
          </Row>
        </Container>
        <CustomModal
          show={showModalEditAdd}
          onHide={() => {
            refreshItems();
          }}
          title={patient._id ? "Editar Paciente" : "Adicionar Paciente"}
          submitLabel={patient._id ? "Salvar Alterações" : "Adicionar Paciente"}
          onSubmit={submitData}
          errorMessage={errorMessage}
          type="form"
        >
          <PatientsForm
            patient={patient}
            handleInputChange={handleInputChange}
          />
        </CustomModal>
        <CustomModal
          show={showModalView}
          onHide={() => {
            refreshItems();
          }}
          title={"Informações do Paciente"}
          submitLabel={"Fechar"}
          type="view"
          data={patient}
        >
          <Row>
            <Col xs={12} sm={4} className="mb-3">
              <h6 className="fw-bold">Paciente</h6>
              <p className="text-muted">{patient.fullName}</p>
            </Col>
            <Col xs={12} sm={12} className="mb-3">
              <h6 className="fw-bold">Informações Adicionais</h6>
              <span
                dangerouslySetInnerHTML={{ __html: patient.additionalInfo }}
              />
            </Col>
          </Row>
        </CustomModal>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default Patients;
