import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Sidebar from "../../../../components/Sidebar";
import { LabelsDashboard } from "../../../../components/Cards/Labels";
import { useAuth } from "../../../../context/authContext";
import { getFirstName } from "../../../../config/utils";
import dashboardService from "../../../../services/dashboardService";
import patientService from "../../../../services/clinicPatientService";
import roleService from "../../../../services/clinicRoleService";
import Loading from "../../../../components/Loading";

function Dashboard() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    totalProfessionals: "0",
    invitedProfessionals: "0",
    totalPatients: "0",
    totalSchedules: "0",
    totalAppointments: "0",
    totalAppointmentsToday: "0",
    totalAppointmentsWeek: "0",
    totalSpecificAppointments: "0",
  });
  const [patients, setPatients] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [filters, setFilters] = useState({
    patient: "all",
    professional: "all",
    status: "all",
    startDate: "",
    endDate: "",
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const [dashboard, patients, professionals] = await Promise.all([
        dashboardService.dashboardClinicInfo(),
        patientService.getPatients(),
        roleService.getProfessionals(),
      ]);

      setData(dashboard);
      setPatients(patients);
      setProfessionals(professionals);

      const dataSpecific = await dashboardService.dashboardAppointmentInfo(
        filters.patient,
        filters.professional,
        filters.status,
        filters.startDate,
        filters.endDate
      );

      setData((prevData) => ({
        ...prevData,
        totalSpecificAppointments: dataSpecific.totalAppointments,
      }));
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    document.title = "We Care Sync - Dashboard";
    fetchData();
  }, [fetchData]);

  const handleInputFiltersChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  return (
    <>
      <Sidebar pageName="Dashboard" pageUrl="/dashboard">
        <Container fluid>
          <Row className="mt-3 mb-4">
            <Col xs={12} md={12}>
              <h5>
                Prazer em te ver novamente,{" "}
                <strong>{getFirstName(user.fullName)}</strong>!
              </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h4>Clínica</h4>
            </Col>
            <Col xs={12} md={4} xxl={3}>
              <LabelsDashboard
                title="Profissionais"
                description="Total de ativos"
                icon="groups"
                count={data.totalProfessionals}
                href="profissionais"
              />
            </Col>
            <Col xs={12} md={4} xxl={3}>
              <LabelsDashboard
                title="Profissinais"
                description="Convites pendentes"
                icon="group_add"
                count={data.invitedProfessionals}
                href="profissionais"
              />
            </Col>
            <Col xs={12} md={4} xxl={3}>
              <LabelsDashboard
                title="Pacientes"
                description="Total de ativos"
                icon="groups"
                count={data.totalPatients}
                href="pacientes"
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <h4>Agenda</h4>
            </Col>
            <Col xs={12} md={4} xxl={3}>
              <LabelsDashboard
                title="Atendimentos"
                description="Total de hoje"
                icon="event_note"
                count={data.totalAppointmentsToday}
                href="atendimentos"
              />
            </Col>
            <Col xs={12} md={4} xxl={3}>
              <LabelsDashboard
                title="Atendimentos"
                description="Total da semana"
                icon="event_note"
                count={data.totalAppointmentsWeek}
                href="atendimentos"
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <h4 className="mb-4">Específico</h4>
              <div className="form-data">
                <Row>
                  <Col xs={12} md={3}>
                    <Form.Group className="mb-3 mb-md-2">
                      <Form.Label className="text-uppercase">
                        Paciente
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="patient"
                        defaultValue={filters.patient || ""}
                        onChange={handleInputFiltersChange}
                        required
                      >
                        <option value="all">Todos</option>
                        {patients.map((item, index) => (
                          <option value={item._id} key={index}>
                            {item.fullName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={3}>
                    <Form.Group className="mb-3 mb-md-2">
                      <Form.Label className="text-uppercase">
                        Profissional
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="professional"
                        defaultValue={filters.professional || ""}
                        onChange={handleInputFiltersChange}
                        required
                      >
                        <option value="all">Todos</option>
                        {professionals.map((item, index) => (
                          <option value={item._id} key={index}>
                            {item.user.fullName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={2}>
                    <Form.Group className="mb-3 mb-md-2">
                      <Form.Label className="text-uppercase">
                        Status
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        defaultValue={filters.status || ""}
                        onChange={handleInputFiltersChange}
                        required
                      >
                        {[
                          { label: "Todos", value: "all" },
                          { label: "Pendentes", value: "pending" },
                          { label: "Confirmado", value: "confirmed" },
                          { label: "Atendido", value: "attended" },
                          {
                            label: "Cancelado sem Justificativa",
                            value: "rejected",
                          },
                          {
                            label: "Cancelado com Justificativa",
                            value: "canceled",
                          },
                          { label: "Rejeitados", value: "rejected" },
                        ].map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={2}>
                    <Form.Group className="mb-3 mb-md-2">
                      <Form.Label className="text-uppercase">
                        Data de Início
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        onChange={handleInputFiltersChange}
                        defaultValue={filters.startDate || ""}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={2}>
                    <Form.Group className="mb-3 mb-md-2">
                      <Form.Label className="text-uppercase">
                        Data de Fim
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        onChange={handleInputFiltersChange}
                        defaultValue={filters.endDate || ""}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4} xxl={3}>
                    <LabelsDashboard
                      title="Atendimentos"
                      description="Total"
                      icon="event_note"
                      count={data.totalSpecificAppointments}
                      href="atendimentos"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default Dashboard;
