import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import {
  CardList,
  CardListHeader,
  CardListHeaderItem,
  CardListBody,
  CardListBodyItem,
  CardListBodyItemWithChildren,
} from "../../../../components/CardList";
import { LabelsInfo } from "../../../../components/Cards/Labels";
import FilterBar from "../../../../components/FiltersBar";
import financialService from "../../../../services/clinicFinancialService";
import roleService from "../../../../services/clinicRoleService";
import patientService from "../../../../services/clinicPatientService";
import {
  dateFormat,
  translateAppointmentStatus,
  getFirstName,
  formatPrice,
  setColorMoney,
} from "../../../../config/utils";
import Swal from "sweetalert2";

function Financial() {
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [info, setInfo] = useState({});
  const [professionals, setProfessionals] = useState([]);
  const [patients, setPatients] = useState([]);

  const [filters, setFilters] = useState({
    page: 1,
    sort: { sortBy: "createdAt", order: "desc" },
    limit: 20,
    search: "",
    patient: "all",
    professional: "all",
    status: "all",
    startDate: "",
    endDate: "",
  });

  const filterConfig = [
    {
      type: "select",
      label: "Paciente",
      name: "patient",
      size: 2,
      options: [
        { label: "Todos", value: "all" },
        ...patients.map((patient) => ({
          label: `${patient.fullName}`,
          value: patient._id,
        })),
      ],
    },
    {
      type: "select",
      label: "Profissional",
      name: "professional",
      size: 2,
      options: [
        { label: "Todos", value: "all" },
        ...professionals.map((professional) => ({
          label: `${professional.user.fullName}`,
          value: professional._id,
        })),
      ],
    },
    {
      type: "select",
      label: "Filtrar por status",
      name: "status",
      options: [
        { label: "Todos", value: "all" },
        { label: "Atendido", value: "attended" },
        { label: "Cancelado sem Justificativa", value: "rejected" },
      ],
      size: 2,
    },
    {
      type: "select",
      label: "# itens",
      name: "limit",
      options: [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
      size: 1,
    },
    {
      type: "date",
      label: "Data de Início",
      name: "startDate",
      size: 2,
    },
    {
      type: "date",
      label: "Data de Fim",
      name: "endDate",
      size: 2,
    },
  ];

  const refreshItems = async () => {
    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const {
        page,
        sort,
        limit,
        search,
        patient,
        professional,
        status,
        startDate,
        endDate,
      } = filters;
      const params = {
        page,
        sortBy: sort.sortBy,
        order: sort.order,
        limit,
        search,
        patient,
        professional,
        status,
        startDate: startDate || null,
        endDate: endDate || null,
      };
      const response = await financialService.fetchData(params);
      const info = await financialService.fetchFinances(params);
      if (firstLoad) {
        await Promise.all([
          roleService.getProfessionals(),
          patientService.getPatients(),
        ]).then((values) => {
          setProfessionals(values[0]);
          setPatients(values[1]);
        });
      }
      setData(response.docs);
      setDataInfo({ ...response });
      setInfo(info);
      setFirstLoad(false);
    } catch (error) {
      console.log(error);
      Swal.fire("Erro!", error.message, "error");
    }
    refreshItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    document.title = "We Care Sync - Financeiro";
    fetchData();
  }, [fetchData]);

  const prevPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
    }));
  };

  const nextPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page:
        prevFilters.page < dataInfo.totalPages
          ? prevFilters.page + 1
          : prevFilters.page,
    }));
  };

  const handleInputSearch = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  return (
    <>
      <Sidebar pageName="Financeiro" pageUrl="/financeiro">
        <Container fluid>
          <FilterBar
            config={filterConfig}
            filters={filters}
            onFilterChange={handleInputSearch}
            adminOnly={true}
          />
          <Row className="mx-2 mt-3">
            <Col xs={12} md={3} xxl={3}>
              <LabelsInfo
                title="Atendimentos"
                description="Totais"
                count={dataInfo.totalDocs || "0"}
                href="#"
              />
            </Col>
            <Col xs={12} md={3} xxl={3}>
              <LabelsInfo
                title="Financeiro"
                description="A receber"
                value={formatPrice(info.totalCobrado || 0)}
                href="#"
              />
            </Col>
            <Col xs={12} md={3} xxl={3}>
              <LabelsInfo
                title="Financeiro"
                description="A pagar"
                value={formatPrice(info.totalProfissional || 0)}
                href="#"
              />
            </Col>
            <Col xs={12} md={3} xxl={3}>
              <LabelsInfo
                title="Financeiro"
                description="Lucro"
                value={formatPrice(info.lucro || 0)}
                href="#"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CardList
                page={parseInt(dataInfo.page)}
                data={data}
                pages={dataInfo.totalPages}
                callbackNext={nextPage}
                callbackPrev={prevPage}
              >
                <CardListHeader className="bg-color-light-gray">
                  <Row>
                    <CardListHeaderItem xs={12} lg={3}>
                      Paciente/Profissional
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={2}>
                      Agenda
                    </CardListHeaderItem>
                    <CardListHeaderItem
                      xs={12}
                      lg={2}
                      className="d-flex justify-content-center"
                    >
                      Custo/Sessão
                    </CardListHeaderItem>
                    <CardListHeaderItem
                      xs={12}
                      lg={2}
                      className="d-flex justify-content-center"
                    >
                      Valor Pago/Sessão
                    </CardListHeaderItem>
                    <CardListHeaderItem
                      xs={12}
                      lg={2}
                      className="d-flex justify-content-center"
                    >
                      Lucro/Sessão
                    </CardListHeaderItem>
                    <CardListHeaderItem
                      xs={12}
                      lg={1}
                      className="d-flex justify-content-center"
                    >
                      Status
                    </CardListHeaderItem>
                  </Row>
                </CardListHeader>

                {data.map((data, index) => (
                  <CardListBody key={index}>
                    <Row>
                      <CardListBodyItemWithChildren
                        xs={12}
                        lg={3}
                        className="text-muted d-inline-flex justify-content-center flex-column"
                        title={"Paciente/Profissional:"}
                      >
                        <small className="mb-0">{data.patient?.fullName}</small>
                        <small>
                          {getFirstName(data.user?.user?.fullName)},{" "}
                          {data.user?.profession}
                        </small>
                      </CardListBodyItemWithChildren>
                      <CardListBodyItem
                        xs={12}
                        lg={2}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Agenda:"}
                        value={`${dateFormat(data.date)}, de ${
                          data.schedule?.startTime
                        } até ${data.schedule?.endTime}`}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={2}
                        className="d-inline-flex align-items-center justify-content-center text-muted small"
                        title={"Custo/Sessão:"}
                        value={`${formatPrice(data.sessionCost)}`}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={2}
                        className="d-inline-flex align-items-center justify-content-center text-muted small"
                        title={"Valor Pago/Sessão:"}
                        value={`${formatPrice(data.user.sessionCost)}`}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={2}
                        className={`d-inline-flex align-items-center justify-content-center small fw-bold text-${setColorMoney(
                          data.sessionCost - data.user.sessionCost
                        )}`}
                        title={"Lucro/Sessão:"}
                        value={`${formatPrice(
                          data.sessionCost - data.user.sessionCost
                        )}`}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={1}
                        className={`d-inline-flex align-items-center justify-content-center small fw-bold text-${translateAppointmentStatus(
                          "color",
                          data.status
                        )}`}
                        title={"Status:"}
                        value={`${translateAppointmentStatus(
                          "label",
                          data.status
                        )}`}
                      />
                    </Row>
                  </CardListBody>
                ))}
                {data.length === 0 ? (
                  <>
                    <CardListBody>
                      <Col
                        xs={12}
                        className="d-inline-flex align-items-center text-muted small justify-content-center"
                      >
                        Nenhum item encontrado.
                      </Col>
                    </CardListBody>
                  </>
                ) : (
                  <></>
                )}
              </CardList>
            </Col>
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default Financial;
